<template>
  <div :class="{
    'd-flex justify-content-center align-items-center m-3': !inline,
    'd-inline-block': inline,
    'jumbo': jumbo,
    'flex-column': flexColumn
  }">

    <div v-if="type === 'spinner'" class="spinner" />

    <div v-if="type === 'dots'">
      <span v-for="n in 3"
            :key="n"
            :class="['d-inline-block', 'dot', 'm-1', {'color': color, 'border-white': invert}]"></span>
    </div>

    <div v-if="$slots.default" :class="contentClasses"><slot /></div>

    <div v-if="simCity" class="text-muted"><em>{{simCityMsg}}</em></div>

  </div>
</template>

<script>
export default {
  name: 'FluencyLoader',
  compatConfig: {
    INSTANCE_SCOPED_SLOTS: false
  },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    jumbo: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
    dots: {
      type: Boolean,
      default: false
    },
    contentClasses: {
      type: String,
      default: 'mt-lg-4 ml-3 text-125'
    },
    invert: {
      type: Boolean,
      default: false
    },
    simCity: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      simCityMsg: null,
      simCityInterval: null,
      simCityArray: [
        // 'Adding Hidden Agendas',
        'Adjusting Bell Curves',
        // 'Aesthesizing Industrial Areas',
        'Aligning Covariance Matrices',
        'Applying Feng Shui Shaders',
        // 'Applying Theatre Soda Layer',
        // 'Asserting Packed Exemplars',
        'Attempting to Lock Back-Buffer',
        // 'Binding Sapling Root System',
        // 'Breeding Fauna',
        'Building Data Trees',
        // 'Bureacritizing Bureaucracies',
        'Calculating Inverse Probability Matrices',
        // 'Calculating Llama Expectoration Trajectory',
        'Calibrating Blue Skies',
        // 'Charging Ozone Layer',
        // 'Coalescing Cloud Formations',
        // 'Cohorting Exemplars',
        // 'Collecting Meteor Particles',
        // 'Compounding Inert Tessellations',
        // 'Compressing Fish Files',
        'Computing Optimal Bin Packing',
        // 'Concatenating Sub-Contractors',
        // 'Containing Existential Buffer',
        // 'Debarking Ark Ramp',
        // 'Debunching Unionized Commercial Services',
        'Deciding What Message to Display Next',
        'Decomposing Singular Values',
        // 'Decrementing Tectonic Plates',
        // 'Deleting Ferry Routes',
        // 'Depixelating Inner Mountain Surface Back Faces',
        // 'Depositing Slush Funds',
        // 'Destabilizing Economic Indicators',
        // 'Determining Width of Blast Fronts',
        // 'Deunionizing Bulldozers',
        'Dicing Models',
        // 'Diluting Livestock Nutrition Variables',
        'Downloading Satellite Terrain Data',
        // 'Exposing Flash Variables to Streak System',
        'Extracting Resources',
        // 'Factoring Pay Scale',
        // 'Fixing Election Outcome Matrix',
        // 'Flood-Filling Ground Water',
        // 'Flushing Pipe Network',
        'Gathering Particle Sources',
        'Generating Jobs',
        // 'Gesticulating Mimes',
        'Graphing Whale Migration',
        // 'Hiding Willio Webnet Mask',
        // 'Implementing Impeachment Routine',
        'Increasing Accuracy of RCI Simulators',
        // 'Increasing Magmafacation',
        // 'Initializing My Sim Tracking Mechanism',
        // 'Initializing Rhinoceros Breeding Timetable',
        'Initializing Robotic Click-Path AI',
        // 'Inserting Sublimated Messages',
        'Integrating Curves',
        // 'Integrating Illumination Form Factors',
        // 'Integrating Population Graphs',
        // 'Iterating Cellular Automata',
        // 'Lecturing Errant Subsystems',
        // 'Mixing Genetic Pool',
        'Modeling Object Components',
        // 'Mopping Occupant Leaks',
        // 'Normalizing Power',
        // 'Obfuscating Quigley Matrix',
        // 'Overconstraining Dirty Industry Calculations',
        // 'Partitioning City Grid Singularities',
        // 'Perturbing Matrices',
        // 'Pixalating Nude Patch',
        // 'Polishing Water Highlights',
        // 'Populating Lot Templates',
        // 'Preparing Sprites for Random Walks',
        // 'Prioritizing Landmarks',
        // 'Projecting Law Enforcement Pastry Intake',
        // 'Realigning Alternate Time Frames',
        // 'Reconfiguring User Mental Processes',
        'Relaxing Splines',
        // 'Removing Road Network Speed Bumps',
        // 'Removing Texture Gradients',
        // 'Removing Vehicle Avoidance Behavior',
        // 'Resolving GUID Conflict',
        'Reticulating Splines',
        // 'Retracting Phong Shader',
        // 'Retrieving from Back Store',
        'Reverse Engineering Image Consultant',
        'Routing Neural Network Infanstructure',
        // 'Scattering Rhino Food Sources',
        // 'Scrubbing Terrain',
        'Searching for Llamas',
        // 'Seeding Architecture Simulation Parameters',
        'Sequencing Particles',
        // 'Setting Advisor Moods',
        // 'Setting Inner Deity Indicators',
        // 'Setting Universal Physical Constants',
        // 'Sonically Enhancing Occupant-Free Timber',
        // 'Speculating Stock Market Indices',
        // 'Splatting Transforms',
        // 'Stratifying Ground Layers',
        // 'Sub-Sampling Water Data',
        'Synthesizing Gravity',
        // 'Synthesizing Wavelets',
        'Time-Compressing Simulator Clock',
        'Unable to Reveal Current Activity',
        // 'Weathering Buildings',
        // 'Zeroing Crime Network'
      ]
    }
  },
  mounted () {
    if (this.simCity) {
      this.generateSimCityMessage()
      this.generateSimCity()
    }
  },
  unmounted () {
    this.destroySimCity()
  },
  computed: {
    type () {
      if (this.dots) {
        return 'dots'
      }

      return 'spinner'
    },
    flexColumn () {
      return this.simCity
    }
  },
  methods: {
    generateSimCity () {
      this.simCityInterval = setInterval(() => {
        this.generateSimCityMessage()
      }, 2500)
    },
    destroySimCity () {
      if (this.simCity) {
        clearInterval(this.simCityInterval)
      }
    },
    generateSimCityMessage () {
      const phrase = this.simCityArray[Math.floor(Math.random() * this.simCityArray.length)]
      this.$setCompat(this, 'simCityMsg', phrase)
    }
  }
}
</script>

<style lang="scss" scoped>
$bw: 4px;

$wOuter: 50px;
$wMiddle: 40px;
$wInner: 30px;

$oOuter: 0;
$oMiddle: 1px;
$oInner: 6px;

$jumboMultiplier: 3;

/* NORMAL SPINNER =================================================================================================== */
.spinner,
.spinner::before,
.spinner::after {
  border-width: $bw;
  border-style: solid;
  border-top-color: transparent!important;
  box-sizing: border-box;
  border-radius: 100%;
  animation: rotate-clockwise 2500ms infinite linear;
}
.spinner::before,
.spinner::after {
  position: absolute;
  display: block;
  content:".";
  color: transparent;
  animation-name: rotate-counterclockwise;
}
.spinner {
  display: inline-block;
  position: relative;
  width: $wOuter;
  height: $wOuter;
  border-right-color: rgba($fluency-green, 0.5);
  border-bottom-color: rgba($fluency-yellow, 0.5);
  border-left-color: rgba($fluency-red, 0.5);
}
.spinner::before {
  top: $oMiddle;
  left: $oMiddle;
  width: $wMiddle;
  height: $wMiddle;
  animation-duration: 1500ms;
  border-right-color: rgba($fluency-red, 0.5);
  border-bottom-color: rgba($fluency-yellow, 0.5);
  border-left-color: rgba($fluency-green, 0.5);
}
.spinner::after {
  top: $oInner;
  left: $oInner;
  width: $wInner;
  height: $wInner;
  animation-duration: 5500ms;
  border-right-color: rgba($fluency-green, 0.5);
  border-bottom-color: rgba($fluency-yellow, 0.5);
  border-left-color: rgba($fluency-red, 0.5);
}

/* JUMBO SPINNER ==================================================================================================== */
.jumbo {
  .spinner,
  .spinner::before,
  .spinner::after {
    border-width: $bw * $jumboMultiplier;
  }
  .spinner {
    width: $wOuter * $jumboMultiplier;
    height: $wOuter * $jumboMultiplier;
  }
  .spinner::before {
    top: $oMiddle * $jumboMultiplier;
    left: $oMiddle * $jumboMultiplier;
    width: $wMiddle * $jumboMultiplier;
    height: $wMiddle * $jumboMultiplier;
  }
  .spinner::after {
    top: $oInner * $jumboMultiplier;
    left: $oInner * $jumboMultiplier;
    width: $wInner * $jumboMultiplier;
    height: $wInner * $jumboMultiplier;
  }
}

/* DOT VERSION ====================================================================================================== */
.dot {
  animation-duration: 600ms;
  animation-name: fade-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  width: 10px;
  height: 10px;
  border-radius: 5px;

  border: 5px solid $fluency-gray;

  &:nth-child(2) { animation-delay: 200ms; }
  &:nth-child(3) { animation-delay: 400ms; }

  &.color {
    border: 5px solid $fluency-pink;

    &:nth-child(2) { border: 5px solid $fluency-yellow; }
    &:nth-child(3) { border: 5px solid $fluency-green; }
  }
}
</style>
